<template>
  <quoc-tich />
</template>
<script>
import QuocTich from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/QuocTich.vue'

export default {
  components: {
    QuocTich,
  },
}
</script>
