<template>
  <div>
    <b-form>
      <b-form-group
        class="required"
        label="Mã quốc tịch"
        label-for="maQuocTich"
      >
        <validation-provider
          v-slot="{ errors }"
          key="maQuocTich"
          :rules="{ required: true }"
          name="maQuocTich"
        >
          <b-form-input
            id="maQuocTich"
            ref="maQuocTich"
            v-model="dataForm.maQuocTich"
            v-trim-input
            type="text"
            :class="{required: errors.length > 0}"
            :autofocus="dataForm.id ? false : true"
            placeholder="Nhập mã quốc tịch"
          />
          <span class="text-danger">{{ errors[0] }}</span>
          <span class="text-danger">{{ errorTrungMa }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="required"
        label="Tên quốc tịch"
        label-for="tenQuocTich"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenQuocTich"
          :rules="{ required: true }"
          name="tenQuocTich"
        >
          <b-form-input
            id="tenQuocTich"
            ref="tenQuocTich"
            v-model="dataForm.tenQuocTich"
            v-trim-input
            type="text"
            :class="{required: errors.length > 0}"
            placeholder="Nhập tên quốc tịch"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Trạng thái"
        label-for="input-1"
      >
        <b-form-checkbox
          v-model="dataForm.status"
          switch
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ghi chú"
        label-for="input-1"
      >
        <b-form-textarea
          id="textarea"
          v-model="dataForm.ghiChu"
          v-trim-input
          placeholder="Nhập ghi chú"
          rows="3"
          max-rows="6"
        />
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
import {
  BFormInput,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
    }
  },
}
</script>
